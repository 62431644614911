<template><div><h1 id="help-support" tabindex="-1"><a class="header-anchor" href="#help-support"><span>Help &amp; Support</span></a></h1>
<p>First and foremost, read all of the documentation here on this site (especially the <RouteLink to="/docs/faq.html">FAQ</RouteLink>), as well as the <a href="https://docs.chrultrabook.com" target="_blank" rel="noopener noreferrer">chrultrabook docs</a></p>
<p>Getting your issue resolved is largely a function of asking for help properly, and in the correct place:</p>
<ul>
<li>
<p>For the quickest help with all issues related to flashing firmware, OS install/drivers, please use the <a href="https://forum.chrultrabook.com/" target="_blank" rel="noopener noreferrer">chrultrabook forums</a></p>
</li>
<li>
<p>Issues running the Firmware Utility Script can be reported on the <a href="https://github.com/MrChromebox/scripts/issues/" target="_blank" rel="noopener noreferrer">scripts issue tracker</a></p>
</li>
<li>
<p>Firmware bugs (<strong>NOT OS issues</strong>) can be reported on the <a href="https://github.com/MrChromebox/firmware/issues/" target="_blank" rel="noopener noreferrer">firmware issue tracker</a></p>
</li>
<li>
<p>Website issues can be reported on the <a href="https://github.com/MrChromebox/website/issues/" target="_blank" rel="noopener noreferrer">website issue tracker</a></p>
</li>
<li>
<p>(Archival info) <a href="http://forum.kodi.tv/showthread.php?tid=194362" target="_blank" rel="noopener noreferrer">Chromebox E-Z Setup Script thread on Kodi forums</a></p>
</li>
<li>
<p>(Archival info) <a href="https://kodi.wiki/view/Archive:Chromebox" target="_blank" rel="noopener noreferrer">Kodi Chromebox wiki page</a></p>
</li>
</ul>
<h2 id="firmware-device-support-requests" tabindex="-1"><a class="header-anchor" href="#firmware-device-support-requests"><span>Firmware/Device Support Requests</span></a></h2>
<p>The firmware updates available via this site are designed to meet the needs of the broadest possible user base, for devices on which I'm able to test (or at least, have a high confidence that it will work). If there's a feature you'd like to see implemented, I'm happy to take requests if it's something that's fairly easy to implement / not determental to the wider user base. Adding support for new devices (eg, Braswell-based Chromebooks) often requires actually having hardware to debug, so if one is able to loan/donate hardware for development purposes, that will definitely speed things along.</p>
<p>Firmware related issues and feature requests are best handled via the <a href="https://github.com/MrChromebox/firmware/issues" target="_blank" rel="noopener noreferrer">firmware issue tracker</a>.</p>
<p>Commerical entities looking for a custom firmware solution for their ChromeOS device(s) should just <a href="mailto:MrChromebox@gmail.com" target="_blank" rel="noopener noreferrer">email me directly</a></p>
<h2 id="unbricking-service" tabindex="-1"><a class="header-anchor" href="#unbricking-service"><span>Unbricking Service</span></a></h2>
<p>If you've taken a wrong turn somehow and, despite the safeguards in the Firmware Utility Script, ended up with a bricked device (whether from my firmware or someone else's), and are unable to recover the device yourself, MrChromebox offers a low-cost unbricking service to anyone in the USA (or anyone who cares to cover shipping to/from really). Simply <a href="mailto:MrChromebox@gmail.com" target="_blank" rel="noopener noreferrer">shoot me an email</a> and we can go from there. With any luck, it might not even be bricked 😃</p>
<p>If your device is bricked, many times it is fairly easy to recover - see <RouteLink to="/docs/support/unbricking/">Unbricking</RouteLink></p>
</div></template>


